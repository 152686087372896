import * as THREE from 'three'
import { GLTFLoader } from '@/plugins/examples/jsm/loaders/GLTFLoader.js'
import store from '@/store'
import CryptoJS from 'crypto-js'
import { GLTFExporter } from '@/plugins/examples/jsm/exporters/GLTFExporter'
import { WebIO } from '@gltf-transform/core';
import { DracoMeshCompression } from '@gltf-transform/extensions'
import { KHRONOS_EXTENSIONS } from '@gltf-transform/extensions'
import { unpartition } from '@gltf-transform/functions'


function setModelJson(model, json) {
  model.position.x = json.position.x
  model.position.y = json.position.y
  model.position.z = json.position.z

  model.rotation.x = json.rotation.x
  model.rotation.y = json.rotation.y
  model.rotation.z = json.rotation.z

  model.scale.x = json.scale.x
  model.scale.y = json.scale.y
  model.scale.z = json.scale.z
}

async function getMergedModelAsync(sourceFiles) {
  return new Promise(async (resolve) => {
    const scene = new THREE.Scene()
    const loader = new GLTFLoader()

    function parseSourceFile(sourceFile) {
      return new Promise((resolve) => {
        if (sourceFile.glb) {
          loader.parse(sourceFile.glb, '', async gltf => {
            setModelJson(gltf.scene, sourceFile.json)
            scene.add(gltf.scene)
            resolve()
          })
        } else {
          resolve()
        }
      })
    }

    const promises = sourceFiles.map(parseSourceFile)

    await Promise.all(promises)
    resolve(scene)
  })
}

function getAnimations(scene) {
  var animations = [];

  scene.traverse(function (object) {
    animations.push(...object.animations);
  });

  return animations;
}

async function publishEditorsModelAsync(scene) {
  const exporter = new GLTFExporter()
  const animations = getAnimations(scene)

  exporter.parse(scene, function (result) {
    (async () => {
      const io = new WebIO().registerExtensions(KHRONOS_EXTENSIONS).registerDependencies({
        'draco3d.encoder': await new window.DracoEncoderModule(),
        'draco3d.decoder': await new window.DracoDecoderModule(),
      })

      // Load an uncompressed GLB file.
      const document = io.readBinary(result)
      // Configure compression settings.
      document
        .createExtension(DracoMeshCompression)
        .setRequired(true)
        .setEncoderOptions({
          method: DracoMeshCompression.EncoderMethod.EDGEBREAKER,
          encodeSpeed: 5,
          decodeSpeed: 5,
        })


      const buffer = document.getRoot().listBuffers()[0];
      document.getRoot().listAccessors()
        .forEach((a) => a.setBuffer(buffer));
      document.getRoot().listBuffers()
        .forEach((b, index) => (index > 0 ? b.dispose() : null));

      await document.transform(unpartition())



      const arrayBuffer = io.writeBinary(document)
      const wordArray = CryptoJS.lib.WordArray.create(arrayBuffer)
      const { uuid } = store.state.Project.project
      const password = uuid.slice(uuid.length - 16)

      // encrypt method
      const key = CryptoJS.enc.Utf8.parse(password)
      const iv = CryptoJS.lib.WordArray.random(16)

      let encryptedArray = CryptoJS.AES.encrypt(wordArray, key, { iv: iv })
      let encrypted = iv.concat(encryptedArray.ciphertext).toString(CryptoJS.enc.Base64)

      const glbFile = new Blob([encrypted], { type: 'application/octet-stream' })
      store.dispatch(
        'Editor/updateGlbEditor',
        { glbFile },
      )
    })()
  },
    { binary: true, animations: animations }
  )
}

export { setModelJson, getMergedModelAsync, publishEditorsModelAsync }