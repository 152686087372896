<template>
  <div class="glb-viewer">
    <canvas :id="sourceFileID" />
  </div>
</template>

<script>
import { GLTFLoader } from '@/plugins/examples/jsm/loaders/GLTFLoader.js'
import { OrbitControls } from '@/plugins/examples/jsm/controls/OrbitControls'
import { RGBELoader } from '@/plugins/examples/jsm/loaders/RGBELoader.js'
import * as THREE from 'three'
import axios from 'axios'
import { setModelJson } from './source-files-utils'
// import { DRACOLoader } from '/src/plugins/examples/jsm/loaders/DRACOLoader.js'


const axiosRequest = axios.create({ Authorization: null, })
axiosRequest.defaults.headers = {
  'Cache-Control': 'no-cache',
}

export default {
  props: {
    glb: ArrayBuffer, // ArrayBuffer object
    json: Object,
    sourceFileID: Number,
  },
  data() {
    return {
      scene: null,
      camera: null,
      renderer: null,
      development_project_files: null,
      productSettings: null,
    }
  },
  computed: {
    filteredSourceFiles() {
      return this.$store.getters["SourceFiles/GET_FILTERED_SOURCE_FILES"].sort((a, b) => a.id - b.id)
    },
    backgroundImage() {
      return this.$store.getters["Editor/getBackgroundImage"]
    },
  },
  methods: {
    async initViewer() {
      const canvas = document.getElementById(this.sourceFileID)
      this.scene = new THREE.Scene()
      this.developmentProjectFiles = this.$store.getters['Project/getCurrentProjectFiles']

      this.renderer = new THREE.WebGLRenderer({
        canvas,
        antialias: true,
      })

      this.renderer.domElement.style.outline = 'none'
      this.renderer.shadowMap.enabled = true
      this.renderer.toneMapping = THREE.LinearToneMapping
      this.renderer.toneMappingExposure = 1.25
      this.renderer.outputEncoding = THREE.sRGBEncoding

      if (this.developmentProjectFiles.environment_hdr_s3_path) {
        this.addEnvMapAsync()
      } else {
        this.addLight()
      }

      this.setBackground()
      await this.addModel()
      this.addCamera()
      this.addOrbitControls()
      this.renderer.setSize(400, 500)
      this.animate();
    },
    async addModel() {
      return new Promise((resolve) => {
        const loader = new GLTFLoader()
        const scope = this
        // const dracoLoader = new DRACOLoader()
        // dracoLoader.setDecoderPath( '/src/plugins/examples/jsm/libs/draco/' )
        // loader.setDRACOLoader( dracoLoader )

        loader.parse(this.glb, '', function (gltf) {
          setModelJson(gltf.scene, scope.json)
          scope.scene.add(gltf.scene)
          resolve()
        })
      })
    },
    addCamera() {
      this.camera = new THREE.PerspectiveCamera(75, 400 / 500, 0.1, 1000)

      // get object size by using bounding box
      const box = new THREE.Box3().setFromObject(this.scene)
      const size = box.getSize()
      const direction = new THREE.Vector3()

      this.scene.getWorldDirection(direction)
      this.camera.position.copy(this.scene.position).add(direction.multiplyScalar((size.x + size.y)))

      this.scene.add(this.camera)
    },
    async addEnvMapAsync() {
      const pmremGenerator = new THREE.PMREMGenerator(this.renderer)
      pmremGenerator.compileEquirectangularShader()
      new RGBELoader().setDataType(THREE.HalfFloatType).load(this.developmentProjectFiles.environment_hdr_s3_path + `?${Math.random()}`, (texture) => {
        const envMap = pmremGenerator.fromEquirectangular(texture).texture
        this.scene.traverse((object) => {
          if (object.isMesh && object.name !== 'skydome') {
            object.material.envMap = envMap
            object.castShadow = true
            object.material.envMapIntensity = 1.1
          }
        });
      });
    },
    addLight() {
      const light = new THREE.AmbientLight(0x404040)

      this.scene.add(light)
    },
    addOrbitControls() {
      const controls = new OrbitControls(this.camera, this.renderer.domElement)

      controls.update()
    },
    animate() {
      requestAnimationFrame(this.animate)
      this.renderer.render(this.scene, this.camera)
    },
    async setBackground() {
      await axiosRequest.get(this.developmentProjectFiles.settings_json_s3_path).then(res => {
        this.productSettings = res.data
      })
      switch (this.productSettings.backgroundType) {
        case 'COLOR':
          const backgroundColor = (parseInt(this.productSettings.backgroundColor.hex.substr(1), 16) << 8) / 256;
          this.scene.background = new THREE.Color(backgroundColor)
          break
        case 'HDR':
          if (this.developmentProjectFiles.background_hdr_s3_path) {
            new RGBELoader()
              .setDataType(THREE.HalfFloatType)
              .load(
                this.developmentProjectFiles.background_hdr_s3_path + `?${Math.random()}`, (texture) => {
                  const pmremGenerator = new THREE.PMREMGenerator(this.renderer)
                  var envMap = pmremGenerator.fromEquirectangular(texture).texture
                  this.scene.background = envMap;
                  texture.dispose()
                  pmremGenerator.dispose()
                })
          }
          break
        case 'IMAGE':
          if (this.developmentProjectFiles.background_img_s3_path) {
            if (!this.backgroundImage || typeof (this.backgroundImage.image) == 'string') {
              new THREE.TextureLoader().load(
                this.developmentProjectFiles.background_img_s3_path + `?${Math.random()}`, (texture) => {
                  this.scene.background = texture
                  this.$store.commit('Editor/SET_BACKGROUND_IMAGE', texture)
                }
              )
            } else {
              this.scene.background = this.backgroundImage
            }
          }
          break
        case 'TRANSPARENT':
          this.renderer.setClearColor(0x000000, 0)
          break
      }
    }
  },
  mounted() {
    this.initViewer()
  },
}
</script>

<style lang="scss">
.glb-viewer {
  width: 100px;
  height: 100px;
}
</style>