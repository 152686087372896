<template>
  <div class="busy-background">
    <div
      v-if="processing"
      class="text-center p-3 bg-primary text-light rounded processing"
    >
      <feather-icon icon="UploadCloudIcon" size="20" />
      <div class="mb-2">Preservation...</div>
      <b-progress
        min="1"
        max="5"
        :value="counter"
        variant="success"
        height="6px"
        class="mx-n3"
      />
    </div>
    <div
      v-else
      ref="dialog"
      tabindex="-1"
      role="dialog"
      aria-modal="false"
      aria-labelledby="form-confirm-label"
      class="text-center p-3 confirmate-buttons"
    >
      <b-card-text class="font-weight-bolder confirmation-text">
        Are you sure?
      </b-card-text>
      <div class="d-flex">
        <b-button
          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
          variant="outline-danger"
          class="mr-3"
          @click="onCancel"
        >
          Cancel
        </b-button>
        <b-button
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="outline-success"
          @click="onOK"
        >
          OK
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "busy-load",
  data() {
    return {
      processing: false,
      counter: 1,
      interval: null,
    };
  },
  props: {
    keepProcessing: Boolean, // If you want to close processing from out of this component
  },
  methods: {
    async onOK() {
      this.processing = true
      this.counter = 1
      if (this.keepProcessing !== true) {
        this.updateProcessing() // In this case processing will be prevented in 5 seconds
      }

      this.$emit("onOK");
    },
    onCancel() {
      this.$emit("onCancel");
    },
    updateProcessing() {
      // Counter is going up from 1 to 5
      // Simulate an async request
      this.clearInterval();
      this.interval = setInterval(() => {
        if (this.counter < 5) {
          this.counter += 1;
        } else {
          this.clearInterval();
          this.$nextTick(() => {
            // eslint-disable-next-line
            this.processing = false;
            this.$emit("onCancel");
          });
        }
      }, 350);
    },
    clearInterval() {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      }
    },
  },
  beforeDestroy() {
    this.clearInterval();
  },
};
</script>

<style>
.busy-background {
  background-color: #80808031;
  width: 100%;
  height: 100%;
  display: block;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 1000;
}
.confirmate-buttons {
  position: fixed;
  top: calc(50% - 50px);
  left: calc(50% - 100px);
}
.processing {
  position: absolute;
  top: calc(50% - 100px);
  left: 50%;
}
.confirmation-text {
  color: rgb(48, 44, 44);
}
</style>