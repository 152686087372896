<template>
  <b-card-code title="Upload Source Files for current Project">
    <b-form class="position-relative" @submit.prevent="onSubmit">
      <b-card-code
        v-for="sourceFile in filteredSourceFiles"
        :key="sourceFile.id"
        border-variant="dark"
        bg-variant="light"
      >
        <div class="source-block">
          <div class="input-block">
            <b-form-group
              :class="{
                'glb-success': sourceFile.glb,
                'glb-warning': !sourceFile.glb,
              }"
              label="Model file"
              label-cols-lg="1"
            >
              <b-form-file
                accept=".glb, .gltf"
                ref="file-input"
                placeholder=".glb, .gltf"
                v-model="sourceFile.glb"
                style="width: 20%; min-width: 100px"
                @change="glbUpdatedAsync($event.target.files[0], sourceFile.id)"
              />
            </b-form-group>
            <b-form-group
              v-if="sourceFile.json && sourceFile.json.position"
              label="Position"
              label-cols-lg="1"
            >
              <div class="inputs">
                <div class="source-input">
                  <span>x</span
                  ><b-form-input
                    @keypress="onlyNumber"
                    @change="jsonUpdated(sourceFile.id)"
                    type="number"
                    v-model="sourceFile.json.position.x"
                  />
                </div>
                <div class="source-input">
                  <span>y</span
                  ><b-form-input
                    @keypress="onlyNumber"
                    @change="jsonUpdated(sourceFile.id)"
                    type="number"
                    v-model="sourceFile.json.position.y"
                  />
                </div>
                <div class="source-input">
                  <span>z</span
                  ><b-form-input
                    @keypress="onlyNumber"
                    @change="jsonUpdated(sourceFile.id)"
                    type="number"
                    v-model="sourceFile.json.position.z"
                  />
                </div>
              </div>
            </b-form-group>
            <b-form-group
              v-if="sourceFile.json && sourceFile.json.rotation"
              label="Rotation"
              label-cols-lg="1"
            >
              <div class="inputs">
                <div class="source-input">
                  <span>x</span
                  ><b-form-input
                    @keypress="onlyNumber"
                    @change="jsonUpdated(sourceFile.id)"
                    type="number"
                    v-model="sourceFile.json.rotation.x"
                  />
                </div>
                <div class="source-input">
                  <span>y</span
                  ><b-form-input
                    @keypress="onlyNumber"
                    @change="jsonUpdated(sourceFile.id)"
                    type="number"
                    v-model="sourceFile.json.rotation.y"
                  />
                </div>
                <div class="source-input">
                  <span>z</span
                  ><b-form-input
                    @keypress="onlyNumber"
                    @change="jsonUpdated(sourceFile.id)"
                    type="number"
                    v-model="sourceFile.json.rotation.z"
                  />
                </div>
              </div>
            </b-form-group>
            <b-form-group
              v-if="sourceFile.json && sourceFile.json.scale"
              label="Scale"
              label-cols-lg="1"
            >
              <div class="inputs">
                <div class="source-input">
                  <span>x</span
                  ><b-form-input
                    @keypress="onlyNumber"
                    @change="jsonUpdated(sourceFile.id)"
                    type="number"
                    v-model="sourceFile.json.scale.x"
                  />
                </div>
                <div class="source-input">
                  <span>y</span
                  ><b-form-input
                    @keypress="onlyNumber"
                    @change="jsonUpdated(sourceFile.id)"
                    type="number"
                    v-model="sourceFile.json.scale.y"
                  />
                </div>
                <div class="source-input">
                  <span>z</span
                  ><b-form-input
                    @keypress="onlyNumber"
                    @change="jsonUpdated(sourceFile.id)"
                    type="number"
                    v-model="sourceFile.json.scale.z"
                  />
                </div>
              </div>
            </b-form-group>
            <b-form-group
              label="JSON code"
              label-cols-lg="1"
              class="json-text-area"
            >
              <b-form-textarea
                v-if="showTextArea"
                rows="10"
                max-rows="11"
                no-resize
                v-model="sourceFile.json"
                @keydown="preventTab"
                @change="textAreaJsonUpdated(sourceFile.id)"
              />
            </b-form-group>
          </div>
          <GLBViewer
            v-if="glbAndJsonAreReady(sourceFile.id) && sourceFile.showGlbViewer"
            :glb="sourceFile.glb"
            :json="sourceFile.json"
            :sourceFileID="sourceFile.id"
          />
        </div>
        <b-button
          variant="outline-danger"
          @click="deleteSourceFile(sourceFile.id)"
          class="mt-2"
          >Delete</b-button
        >
      </b-card-code>
      <b-button variant="outline-primary" @click="addSourceFile"
        >Add Source File</b-button
      >
      <div class="d-flex justify-content-center mt-2">
        <b-button
          ref="submit"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          type="submit"
          :disabled="busyButton"
        >
          Merge files and submit
        </b-button>
      </div>
      <busy-load
        v-if="busy"
        no-wrap
        @onOK="onOK"
        @onCancel="onCancel"
        ref="busyRef"
        :keepProcessing="true"
      />
    </b-form>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code"
import BusyLoad from "/src/views/components/interface/BusyLoad.vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent"
import GLBViewer from './GLBViewer.vue'
import { getMergedModelAsync, publishEditorsModelAsync } from './source-files-utils'
import store from '@/store'


export default {
  components: {
    BCardCode,
    BusyLoad,
    GLBViewer,
  },
  data() {
    return {
      busy: false,
      busyButton: true,
      processing: false,
      counter: 1,
      interval: null,
      editorIsLoaded: true,
      showTextArea: true,
    }
  },
  computed: {
    filteredSourceFiles() {
      return this.$store.getters["SourceFiles/GET_FILTERED_SOURCE_FILES"].sort((a, b) => a.id - b.id)
    },
    sourceFiles() {
      return this.$store.getters["SourceFiles/GET_SOURCE_FILES"]
    },
  },
  mounted() {
    this.$store
      .dispatch(
        "SourceFiles/LOAD_SOURCE_FILES_BY_EDITOR",
        this.$store.state.Editor.editor.id
      )
      .then(() => (this.busyButton = false))
  },
  methods: {
    glbAndJsonAreReady(sourceFileID) {
      const sourceFile = this.sourceFiles.find(sf => sf.id == sourceFileID)
      let glbIsReady = sourceFile.glb instanceof ArrayBuffer
      let jsonIsReady = sourceFile.json instanceof Object
      return glbIsReady && jsonIsReady
    },
    async glbUpdatedAsync(file, sourceFileID) {
      const sourceFile = this.sourceFiles.find(sf => sf.id == sourceFileID)
      sourceFile.requestType = sourceFile.requestType != 'creating' ? 'updating' : 'creating'
      let glb = file

      if (glb instanceof File) {
        glb = await glb.arrayBuffer()
      }
      this.$store.commit('SourceFiles/SET_SOURCE_FILE_GLB', { glb, sourceFileID })

      // Indicate changes
      sourceFile.isChanged = true
      if (sourceFile.changedData) {
        sourceFile.changedData.add('glb')
      } else {
        sourceFile.changedData = new Set(['glb'])
      }
      this.blinkGlbViewer(sourceFile)
    },
    textAreaJsonUpdated(sourceFileID) {
      const sourceFile = this.sourceFiles.find(sf => sf.id == sourceFileID)
      sourceFile.requestType = sourceFile.requestType != 'creating' ? 'updating' : 'creating'
      const json = sourceFile.json
      try {
        let parsedJson = JSON.parse(json)
        this.checkJson(parsedJson)
        this.$store.commit('SourceFiles/SET_SOURCE_FILE_JSON', { json: parsedJson, sourceFileID })

        // Indicate changes
        sourceFile.isChanged = true
        if (sourceFile.changedData) {
          sourceFile.changedData.add('json')
        } else {
          sourceFile.changedData = new Set(['json'])
        }
        this.blinkGlbViewer(sourceFile)
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            text: "Wrong JSON format! Try again please",
            title: "Format Error",
            variant: 'danger',
            autoHideDelay: 5000,
            icon: "AlertTriangleIcon",
          }
        })
        // TODO: Revert changes if wrong json format
      }
    },
    jsonUpdated(sourceFileID) {
      const sourceFile = this.sourceFiles.find(sf => sf.id == sourceFileID)
      sourceFile.requestType = sourceFile.requestType != 'creating' ? 'updating' : 'creating'
      const json = sourceFile.json

      // Change json values type to Number (for gltf_to_usdz utils correct work)
      for (let jsonFiled in json) {
        for (let filedLetter in json[jsonFiled]) {
          json[jsonFiled][filedLetter] = Number(json[jsonFiled][filedLetter])
        }
      }

      this.$store.commit('SourceFiles/SET_SOURCE_FILE_JSON', { json, sourceFileID })

      // Indicate changes
      sourceFile.isChanged = true
      if (sourceFile.changedData) {
        sourceFile.changedData.add('json')
      } else {
        sourceFile.changedData = new Set(['json'])
      }
      this.blinkTextArea()
      this.blinkGlbViewer(sourceFile)
    },
    addSourceFile() {
      let id = 0

      if (this.sourceFiles.length > 0) {
        let lastSourceFile = this.sourceFiles.reduce((a, b) => a.id > b.id ? a : b)
        id = lastSourceFile.id + 1
      }

      this.$store.commit("SourceFiles/ADD_SOURCE_FILE", {
        id,
        glb: null,
        json: {
          position: { x: 0, y: 0, z: 0 },
          rotation: { x: 0, y: 0, z: 0 },
          scale: { x: 1, y: 1, z: 1 },
        },
        requestType: 'creating',
        showGlbViewer: true,
      });
    },
    blinkTextArea() {
      this.showTextArea = false
      const scope = this
      setTimeout(() => {
        scope.showTextArea = true
      }, 1)
    },
    blinkGlbViewer(sourceFile) {
      sourceFile.showGlbViewer = false
      setTimeout(() => {
        sourceFile.showGlbViewer = true
      }, 1)
    },
    onSubmit() {
      if (this.editorIsLoaded) {
        this.processing = false
        this.busy = true
        this.busyButton = true
      }
    },
    async onOK() {
      function processSourceFile(sourceFile) {
        return new Promise((resolve) => {
          const jsonData = JSON.stringify({
            position: sourceFile.json.position,
            rotation: sourceFile.json.rotation,
            scale: sourceFile.json.scale,
          })
          const sourceFileData = {
            id: sourceFile.id,
            glbFile: sourceFile.glb,
            jsonData,
            editorID: store.state.Editor.editor.id,
            changedData: sourceFile.changedData,
          }
          switch (sourceFile.requestType) {
            case 'deleting':
              store.dispatch("SourceFile/DELETE_SOURCE_FILE", sourceFile.id).then(res => {
                resolve()
              }).catch(error => {
                resolve()
              })
              break
            case 'creating':
              store.dispatch("SourceFile/POST_SOURCE_FILE", sourceFileData).then(res => {
                if (res.status == 201) {
                  sourceFile.localOnly = false
                  sourceFile.id = res.data.id
                  resolve()
                }
              }).catch(error => {
                resolve()
              })
              break
            case 'updating':
              store.dispatch("SourceFile/PATCH_SOURCE_FILE", sourceFileData).then(res => {
                resolve()
              }).catch(error => {
                resolve()
              })
              break
            default:
              resolve()
          }
        })
      }

      if (this.filteredSourceFiles && this.filteredSourceFiles.length > 0) {
        await Promise.all(this.sourceFiles.map(processSourceFile))
        await this.resetSourceFilesAfterSubmit()
        await this.mergeAndPublishAllModels()
      } else {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            text: 'There should be at least one Source File',
            title: 'Wrong data',
            variant: 'danger',
            autoHideDelay: 5000,
            icon: "AlertTriangleIcon",
          }
        })
      }
      this.$refs.busyRef.updateProcessing()
    },
    async mergeAndPublishAllModels() {
      let mergedModelsScene = await getMergedModelAsync(this.filteredSourceFiles)
      await publishEditorsModelAsync(mergedModelsScene)
    },
    deleteSourceFile(sourceFileID) {
      const sourceFile = this.sourceFiles.find(sourceFile => sourceFile.id == sourceFileID)

      if (sourceFile.requestType == 'creating') {
        // Just created Source Files should be deleted from local store only
        this.$store.commit('SourceFiles/REMOVE_SOURCE_FILE', sourceFile.id)
      } else {
        // Delete status allows to delete Source File from database
        this.$store.commit('SourceFiles/ADD_DELETE_STATUS_FOR_SOURCE_FILE', sourceFile.id)
      }
    },
    onCancel() {
      this.busy = false
      this.busyButton = false
    },
    async resetSourceFilesAfterSubmit() {
      await this.$store.commit("SourceFiles/REMOVE_DELETED_SOURCE_FILES")
      await this.$store.commit("SourceFiles/RESET_SOURCE_FILES")
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 45) {
        $event.preventDefault()
      }
    },
    preventTab(event) {
      if (event.key == 'Tab') {
        event.preventDefault()
      }
    },
    checkJson(json) {
      // check if json is correct
      if (!json.position || !json.rotation || !json.scale) {
        throw Error("Wrong json format")
      }
      for (let i in json) {
        if (isNaN(json[i].x) || isNaN(json[i].y) || isNaN(json[i].z)) {
          throw Error("Wrong json format")
        }
      }
    },
  },
}
</script>

<style lang="scss">
.source-block {
  display: flex;
  flex-direction: row;

  .input-block {
    width: 70%;
  }
}
.glb-success {
  label {
    border-color: rgb(143, 238, 143);
  }
}
.glb-warning {
  label {
    border-color: rgb(209, 109, 109);
  }
}
.inputs {
  display: flex;
  flex-direction: row;
  gap: 40px;

  .source-input {
    display: flex;
    gap: 3px;
    align-items: center;

    span {
      margin-right: 2px;
    }
    input {
      width: 60px;
      padding: 3px;
    }
  }
}
.json-text-area {
  max-width: 50vw;
  min-width: 300px;
}
</style>
